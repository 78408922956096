export default {
    methods: {
        handleRouterLinkClick(model, gaEvent = null) {
            if (gaEvent && this.$gtag && this.$gtag.event) this.$gtag.event(gaEvent.name, gaEvent.data)
            this.$trackEvent('CLICK', {
                name: model.name,
                content_type: model.content_type.slug,
                id: model.id,
            })
        },
        navigateTo(route, blank = false) {
            if (blank) {
                const routeData = this.$router.resolve(route)
                window.open(routeData.href, '_blank')
            } else this.$router.push(route)
        },
    },
}
